import React from 'react';

import {
  Text,
  PageHeading,
  Heading
} from '../../components';

const Documentation = ({ heading, paragraphs }) => (
  <>
    <PageHeading value={heading} />
    {paragraphs.map(({ heading, text }) => (
      <>
        <Heading
          value={heading}
          style={{
            fontSize: '2.4em',
            marginTop: '1rem'
          }}
        />
        <Text style={{
          fontSize: '1.2em',
          lineHeight: '2em',
          marginTop: '1rem'
        }}>{text}</Text>
      </>
    ))}
  </>
);

export default Documentation;
