import { Image, View } from 'react-native';

import { Heading } from '..';

const Listing = ({
  id,
  photo,
  cta,
  buyer
}) => (
  <View key={id} style={{
    backgroundColor: 'white',
    boxShadow: '.3rem .3rem 0 black',
    marginBottom: '1rem'
  }}>
    <View style={{
      width: '100%',
      height: '20rem'
    }}>
      <Image
        source={{
          uri: photo
        }}
        style={{
          backgroundColor: '#eee',
          width: '100%',
          height: '100%',
          filter: buyer ? 'saturate(0.1) sepia(.2)' : 'none',
          opacity: buyer ? .5 : 1
        }}
      />
    </View>
    {buyer
      ? <Heading value="SOLD" style={{
        textAlign: 'center'
      }} />
      : cta
    }
  </View>
);

export default Listing;
