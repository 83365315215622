import { StyleSheet } from 'react-native';

const BASE_CONTAINER_STYLES = {
  marginLeft: 'auto',
  marginRight: 'auto'
};

const styles = StyleSheet.create({
  medium: {
    ...BASE_CONTAINER_STYLES,

    width: '900px'
  },
  mobile: {
    ...BASE_CONTAINER_STYLES,

    width: '90vw'
  }
});

export default styles;
