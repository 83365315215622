import { StyleSheet } from 'react-native';

const BASE_TEXT_STYLES = {
  fontSize: '1em',
  fontWeight: '400',
  fontFamily: 'Open Sans, sans-serif',
  color: 'black'
};

const styles = StyleSheet.create({
  primary: BASE_TEXT_STYLES,
  
  secondary: {
    ...BASE_TEXT_STYLES,

    color: 'white'
  },

  small: {
    ...BASE_TEXT_STYLES,

    fontSize: '.86em',
    fontWeight: '600'
  }
});

export default styles;
