import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  TextInput,
  Picker,
  View
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Text,
  Heading
} from '../../components';

import { BASE_URL } from '../../.env';

const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

const REDIRECTS = {
  shipping: 'cart',
  seller: 'publish'
};

const INFO = {
  shipping: 'We need your address to calculate shipping rates.',
  seller: 'We need your valid shipping address on file first.'
};
const TEXT_INPUT_STYLE = {
  margin: '.5rem',
  padding: '1rem',
  fontSize: '1.5em',
  fontFamily: 'Open Sans, sans-serif',
  appearance: 'none',
  border: 'none',
  background: 'black',
  color: 'white',
  borderBottom: '1px solid white',
  outline: 'none'
};

const PICKER_STYLE = {
  margin: '.5rem',
  padding: '1rem',
  fontSize: '1.5em',
  fontFamily: 'Open Sans, sans-serif',
  appearance: 'none',
  border: 'none',
  background: 'black',
  color: 'white',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  outline: 'none'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Address = () => {
  const user = getSessionUser();
  const { redirect = 'shop' } = useParams();
  const [street1, setStreet1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  useEffect(() => {
    if (user?.userData?.shippingAddress) {
      const { shippingAddress } = user.userData;

      setStreet1(shippingAddress.street1);
      setCity(shippingAddress.city);
      setState(shippingAddress.state);
      setZip(shippingAddress.zip);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPressSave = async () => {
    // eslint-disable-next-line no-useless-escape
    if (!(/^[a-z0-9_\-\ \#.]{3,64}$/i.test(street1))) {
      alert('Invalid street address.');

      return;
    }

    // eslint-disable-next-line no-useless-escape
    if (!(/^[a-z_\-\ .]{2,64}$/i.test(city))) {
      alert('Invalid city.');

      return;
    }

    if (!state || state.length !== 2) {
      alert('Invalid state.');

      return;
    }

    if (!zip || zip.length < 5) {
      alert('Invalid zip.');

      return;
    }

    const response = await fetch(`${BASE_URL}/address`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user.username,
        token: user.token,
        street1,
        city,
        state,
        zip
      })
    });

    if (response?.ok) {
      sessionStorage.setItem('user', JSON.stringify({
        ...user,

        userData: {
          ...user.userData,

          shippingAddress: {
            ...user.userData.shippingAddress,

            street1,
            city,
            state,
            zip
          }
        }
      }));

      alert('Address updated!');

      window.location.href = `/${REDIRECTS[redirect] || 'shop'}`;

      return;
    }
  };

  if (!user?.userData) {
    window.location.href = '/signin';

    return;
  }

  return (
    <View style={{
      backgroundColor: 'black',
      marginTop: '1rem',
      padding: '2rem',
      display: 'flex'
    }}>
      <Heading secondary value="Shipping address" />
      <Text
        secondary
        style={{ fontSize: '1.4em' }}
      >{INFO[redirect] || INFO.seller}</Text>
      <View style={{ marginTop: '2rem' }}>
        <Text secondary>Street address</Text>
        <TextInput
          type="text"
          placeholder="123 Any Ave. #4"
          onChangeText={value => setStreet1(value)}
          value={street1}
          style={TEXT_INPUT_STYLE}
        />
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>City</Text>
        <TextInput
          type="text"
          placeholder="San Francisco"
          onChangeText={value => setCity(value)}
          value={city}
          style={TEXT_INPUT_STYLE}
        />
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>State</Text>
        <Picker
          selectedValue={STATES[state]}
          onValueChange={value => setState(
            Object.keys(STATES).find(key => (
              STATES[key] === value
            )) || 'CA'
          )}
          style={PICKER_STYLE}
        >
          {Object.values(STATES).map(label => (
            <Picker.Item key={label} label={label} />
          ))}
        </Picker>
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>ZIP</Text>
        <TextInput
          type="text"
          placeholder="12345"
          onChangeText={value => setZip(value)}
          value={zip}
          style={TEXT_INPUT_STYLE}
        />
      </View>
      <Button
        center
        secondary
        onPress={onPressSave}
        value={
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text>Save</Text>
            <FontAwesomeIcon icon={faPlus} />
          </View>
        }
      />
    </View>
  );
};

export default Address;
