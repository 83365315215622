const FAQ = {
  heading: 'Frequently Asked Questions',
  paragraphs: [
    {
      heading: 'What does Kickflip do?',
      text: 'Kickflip helps skaters find single shoe replacements from iconic skate brands at a discounted price.'
    },
    {
      heading: 'Is it free?',
      text: 'Yes, it\'s free to sign up and list your products for sale.'
    },
    {
      heading: 'Is it available outside the US?',
      text: 'Not yet, but we have plans to expand internationally. Right now though we cannot reliably provide shipping rates or take payments outside the US.'
    },
    {
      heading: 'Can anyone use it?',
      text: 'You can use Kickflip if you are over the age of 13 and live within the US.'
    },
    {
      heading: 'Does Kickflip take a commission?',
      text: 'There is a 10% commission kept from each sale to cover processing costs and service provider fees, but no listing fee or any other hidden fees.'
    },
    {
      heading: 'Who pays shipping?',
      text: 'The buyer pays shipping at checkout.'
    },
    {
      heading: 'How do you handle payments?',
      text: 'The payments library behind Kickflip is based on Square, who very securely manages compliance around credit card entry and sales tax where applicable.'
    },
    {
      heading: 'How do you handle shipping?',
      text: 'Kickflip uses Shippo to generate USPS shipping labels; they provide nice out-the-door quotes that are surfaced to the user at checkout. USPS shipping labels are automatically paid for and emailed to the seller whenever they sell a product.'
    },
    {
      heading: 'How do I become a seller on the platform?',
      text: 'Login to your account and click the "List Shoe" button. Follow the prompts to enter a valid US postal address and email address you have on file with Fern, our payouts partner. If you need a Fern account, click "Create Fern Account" to open it. You don\'t need to add any payment methods or do anything else. That\'s it! Now you can list products for sale on Kickflip. Fern is where you can process your payouts, view payments history, manage your customer base, and perform other seller-related actions.',
    },
    {
      heading: 'How soon should I ship a shoe that I sold?',
      text: 'The sooner the better of course, but we ask that sellers print the label and drop it in a mailbox within 72 hours. Buyers appreciate same-day shipping and are more likely to buy from you again if you ship fast.'
    },
    {
      heading: 'What is the most I can sell a used shoe for?',
      text: 'In order to control shoe quality and prevent scams on the platform, there are just 3 fixed pricing tiers, based on the quality of the shoe: "Good" ($15), "Great" ($20), and "New" ($25). It\'s up to the seller to choose the appropriate quality and upload 3 clear photos to validate their listing. Brand hype, current demand, or shelf price are not taken into consideration for pricing, it\'s based on shoe quality alone. Kickflip reserves the right to "QA" or remove inappropriate listings.'
    },
    {
      heading: 'How do I get paid?',
      text: 'Login to your Fern account and click "Withdraw" in the top-right. You can withdraw funds from transactions completed more than 14 days ago. Funds can currently be paid out to both Cash App and Venmo, where you can instantly transfer to your debit card, or process a standard deposit into your bank account.'
    },
    {
      heading: 'Are my sales considered income?',
      text: 'Yes, selling on Kickflip, as with any other online selling, should be reported to the IRS by the seller as income.'
    },
    {
      heading: 'Can I buy shoes with my current balance?',
      text: 'Yes, Fern is designed around the use case of sellers who are also sometimes buyers. If you have a positive balance, you can spend it on the platform just like money.'
    },
    {
      heading: 'Why hasn\'t the seller shipped my purchase yet?',
      text: 'We ask that sellers ship within 72 hours after the purchase was made. If it\'s been longer than a week, feel free to reach out and we can ask them if they sent it yet.'
    },
    {
      heading: 'Can I cancel the purchase and get a refund?',
      text: 'Once the purchase has been made and the seller prompted to ship, the order cannot be canceled. This keeps listings on the platform legit and reliable, which is good for both buyers and sellers.'
    },
    {
      heading: 'How can I contact customer service?',
      text: 'You can always contact the creator of Kickflip directly at benny@exactchange.network.'
    }
  ]
};

export {
  FAQ
};
