import React, { useState } from 'react';
import { View } from 'react-native';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';

import {
  Loading,
  Text
} from '..';

import {
  BASE_URL,
  SQUARE_APP_ID,
  SQUARE_LOCATION_ID
} from '../../.env';

const Payment = ({
  username,
  token,
  userData: {
    shippingAddress: {
      name: givenName,
      street1,
      city,
      state,
      zip
    }
  },
  listings,
  amount
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onPressPay = async ({
    token: squareToken
  }) => {
    setIsLoading(true);

    if (!username || !token) {
      window.location.href = '/signin';

      return;
    }

    if (!squareToken) {
      return (
        <Text secondary>Square is offline.</Text>
      );
    }

    if (!street1 || !city || !state || !zip) {
      window.location.href = '/address/shipping';

      return;
    }

    if (!listings?.length) {
      alert('Cart is empty.');

      return;
    }

    if (!amount) {
      alert('Invalid dollar amount.');

      return;
    }

    const response = await fetch(
      `${BASE_URL}/purchase`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          token,
          squareToken,
          address1: street1,
          address2: `${city}, ${state} ${zip}`,
          listings,
          amount
        })
      }
    );

    setIsLoading(false);

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        alert('Thanks for your purchase! Check your email for confirmation.');

        window.location.href = '/shop';
      }
    }
  };

  return isLoading ? <Loading /> : (
    <View style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '2rem'
    }}>
      <View style={{
        width: '20rem',
        maxWidth: '100%'
      }}>
        <PaymentForm
          applicationId={SQUARE_APP_ID}
          locationId={SQUARE_LOCATION_ID}
          cardTokenizeResponseReceived={token => onPressPay(token)}

          createVerificationDetails={() => ({
            amount,
            billingContact: {
              addressLines: [street1, `${city}, ${state} ${zip}`],
              givenName,
              countryCode: 'US'
            },
            currencyCode: 'USD',
            intent: 'CHARGE'
          })}
        >
          <CreditCard />
        </PaymentForm>
      </View>
    </View>
  );
};

export default Payment;
