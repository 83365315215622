import { View, useWindowDimensions } from 'react-native';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import {
  faEnvelope,
  faCartShopping,
  faUser
} from '@fortawesome/free-solid-svg-icons';

import {
  Heading,
  Link,
  Text
} from '..';

import styles from './styles';

import { FERN_URL } from '../../.env';

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Page = ({
  style = {},
  children = '',
  background = true,
  banner = false,

  ...props
}) => {
  const isMobile = useWindowDimensions().width < 900;
  const { pathname } = useLocation();
  const user = getSessionUser();

  return (
    <>
      {banner && <Heading
        banner
        style={{
          backgroundColor: '#ccc',
          color: 'white'
        }}
        value="Kickflip"
      />}
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'black',
        width: '12rem',
        padding: '.5rem',
        position: 'fixed',
        zIndex: 100,
        right: 0,
        top: 0
      }}>
        {user?.userData ? (
          <>
            <View>
              <Link href={FERN_URL} target="_blank">
                <Text secondary>0.00 USD</Text>
              </Link>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row'
            }}>
              <View style={{ marginLeft: '.75rem' }}>
                <Link href="/cart">
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    style={{ color: 'white' }}
                  />
                </Link>
              </View>
              <View style={{ marginLeft: '.75rem' }}>
                <Link href="/messages">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: 'white' }}
                  />
                </Link>
              </View>
              <View style={{ marginLeft: '.75rem' }}>
                <Link href={`/${user.userData.displayName}`}>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: 'white' }}
                  />
                </Link>
              </View>
            </View>
          </>
        ) : (
          <Link href="/signin">
            <Text secondary>Sign in</Text>
          </Link>
        )}
      </View>
      {pathname !== '/' && <Link
        href={pathname === '/shop' ? '/' : '/shop'}
        style={{ textDecoration: 'none' }}
      >
        <Heading logo value="Kickflip" />
      </Link>}
      <View
        {...props}

        style={{
          ...style,

          ...(background
            ? isMobile
              ? styles.mobileBackground
              : styles.background
            : isMobile
              ? styles.mobileBlank
              : styles.blank
          )
        }}
      >
        {children}
        <View style={{
          backgroundColor: 'black',
          position: 'fixed',
          zIndex: 90,
          bottom: 0,
          left: 0,
          right: 0,
          top: 'auto',
          height: '3rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <Text secondary style={{
            marginLeft: '1rem',
            display: 'flex'
          }}>
            &copy; {new Date().getFullYear()} Kickflip
          </Text>
          <Link href="/faq">
            <Text secondary style={{
              marginLeft: '.5rem',
              display: 'flex'
            }}>
              FAQ
            </Text>
          </Link>
          <Link href="/privacy">
            <Text secondary style={{
              marginLeft: '.5rem',
              display: 'flex'
            }}>
              Privacy
            </Text>
          </Link>
        </View>
      </View>
    </>
  );
};

export default Page;
