import { View } from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

/**
 * ImagePicker
 * Based on web native <input />
 *
 * Should be replaced in iOS and Android with
 * respective native file input components
 */

const ImagePicker = ({ onChange, ...props }) => (
  <View>
    <input
      id="picker"
      name="picker"
      type="file"
      accept="image/png, image/jpeg"

      onChange={({ target: { files }}) => {
        const reader = new FileReader();

        reader.onload = ({ target: { result }}) => {
          onChange(result);
        };

        reader.readAsDataURL(files[0]);
      }}

      {...props}
    />
    <label className="input" htmlFor="picker">
      <FontAwesomeIcon
        icon={faImage}
        style={{ color: '#ccc' }}
        size={50}
      />
    </label>
  </View>
);

export default ImagePicker;
