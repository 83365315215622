import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  TextInput,
  View
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Link,
  Text,
  Heading
} from '../../components';

import {
  BASE_URL,
  FERN_URL
} from '../../.env';

const REDIRECTS = {
  profile: 'profile',
  seller: 'publish'
};

const TEXT_INPUT_STYLE = {
  margin: '.5rem',
  padding: '1rem',
  fontSize: '1.5em',
  fontFamily: 'Open Sans, sans-serif',
  appearance: 'none',
  border: 'none',
  background: 'black',
  color: 'white',
  borderBottom: '1px solid white',
  outline: 'none'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Fern = () => {
  const user = getSessionUser();
  const { redirect = 'profile' } = useParams();
  const [fernUsername, setFernUsername] = useState('');

  useEffect(() => {
    if (user?.userData?.fernUsername) {
      setFernUsername(user.userData.fernUsername);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPressSave = async () => {
    if (!fernUsername) return;

    const response = await fetch(`${BASE_URL}/fern`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: user.username,
        token: user.token,
        fernUsername
      })
    });

    if (response?.ok) {
      sessionStorage.setItem('user', JSON.stringify({
        ...user,

        userData: {
          ...user.userData,

          fernUsername
        }
      }));

      alert('Fern username updated!');

      window.location.href = `/${REDIRECTS[redirect]}`;

      return;
    }
  };

  return (
    <View style={{
      backgroundColor: 'black',
      marginTop: '1rem',
      padding: '2rem',
      display: 'flex'
    }}>
      <Heading secondary value="Fern" />
      <Text
        secondary
        style={{ fontSize: '1.4em' }}
      >Enter your Fern username below to receive payouts when you sell items. Your Fern username is the email address you registered with.</Text>
      <View style={{ marginTop: '2rem' }}>
        <Text secondary>Fern email address</Text>
        <TextInput
          type="email"
          placeholder="hello@example.com"
          onChangeText={value => setFernUsername(value)}
          value={fernUsername}
          style={TEXT_INPUT_STYLE}
        />
      </View>
      <Button
        center
        secondary
        onPress={onPressSave}
        value={
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text>Save</Text>
            <FontAwesomeIcon icon={faPlus} />
          </View>
        }
      />
      <View>
        <Link
          href={FERN_URL}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '1.2em'
          }}
        >
          <Text secondary style={{ textDecoration: 'underline' }}>
            Create a Fern account
          </Text>
        </Link>
      </View>
    </View>
  );
};

export default Fern;
