import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Product,
  Heading,
  PageHeading,
  Payment,
  Text,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const PRICES = {
  'Good': '15.00',
  'Great': '20.00',
  'New': '25.00'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Cart = () => {
  const [cart, setCart] = useState();
  const [total, setTotal] = useState('0.00');

  const user = getSessionUser();

  const fetchCart = async () => {
    const response = await fetch(
      `${BASE_URL}/get-user`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.username,
          token: user.token
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.cart) {
        const products = result.cart;

        let subtotal = 0;
        let shipping = 0;

        for (const product of products) {
          subtotal += +PRICES[product.condition];
          shipping += product.shipping || 9;
        }

        setTotal(parseFloat(subtotal + shipping).toFixed(2));
        setCart(products);
      }
    }
  };

  useEffect(() => {
    fetchCart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPressShop = () => (
    window.location.href = '/shop'
  );

  const onPressRemove = listingId => async () => {
    if (!listingId) return;

    const response = await fetch(
      `${BASE_URL}/edit-cart`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.username,
          token: user.token,
          listingId
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        fetchCart();
      }
    }
  };

  if (!user?.userData) {
    window.location.href = '/signin';

    return <Text>Redirecting...</Text>;
  }

  if (!cart) {
    return <Loading />;
  }

  const isEmpty = cart.length === 0;

  if (!isEmpty && !user?.userData?.shippingAddress?.street1) {
    window.location.href = '/address/shipping';

    return <Text>Redirecting...</Text>;
  }

  return (
    <>
      <PageHeading value="Cart" />
      {cart.map(item => (
        <Product
          key={item.id}

          {...item}

          onPressRemove={onPressRemove(item.id)}
        />
      ))}
      <View style={{
        backgroundColor: 'black',
        marginTop: '1rem',
        padding: '2rem',
        display: 'flex'
      }}>
        <Heading
          secondary
          center
          style={{
            lineHeight: '1em'
          }}
          value={isEmpty ? 'Cart is empty' : 'Checkout'}
        />
        <Heading
          secondary
          center
          style={{
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '1.5em',
            fontWeight: '600',
            marginTop: '1rem'
          }}
          value={`Total: $${total}`}
        />
        {!isEmpty && <Payment
          {...user}

          listings={cart.map(({ id }) => id)}
          amount={total}
        />}
        <Button
          center
          onPress={onPressShop}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text secondary>{isEmpty ? 'C' : 'Or, c'}ontinue shopping</Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: 'white' }}
              />
            </View>
          }
        />
      </View>
    </>
  );
};

export default Cart;
