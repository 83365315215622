import React from 'react';
import { View } from 'react-native';

import { Heading } from '..';

const PageHeading = ({ value = '', ...props }) => (
  <View style={{
    marginTop: '4rem',
    marginBottom: '2rem',

    ...props.style
  }}>
    <Heading {...props} value={value} />
  </View>
);

export default PageHeading;
