import { ActivityIndicator, View } from 'react-native';

const Loading = props => (
  <View {...props} style={{
    position: 'fixed',
    zIndex: 1000,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...props.style
  }}>
    <ActivityIndicator color="black" />
  </View>
);

export default Loading;
