import { Text } from 'react-native';

const Link = props => (
  <Text 
    {...props} 

    role='link' 

    style={{ 
      fontWeight: '600', 
      fontSize: '1em',
      textDecoration: 'underline',

      ...props.style
    }} 
  />
);

export default Link;
