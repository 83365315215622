import Button from './Button';
import Container from './Container';
import Documentation from './Documentation';
import ImagePicker from './ImagePicker';
import Heading from './Heading';
import PageHeading from './PageHeading';
import Loading from './Loading';
import Listings from './Listings';
import Listing from './Listing';
import Payment from './Payment';
import Product from './Product';
import Link from './Link';
import Note from './Note';
import Page from './Page';
import Text from './Text';

export {
  Button,
  Container,
  Documentation,
  Heading,
  ImagePicker,
  Loading,
  PageHeading,
  Listings,
  Listing,
  Payment,
  Product,
  Link,
  Note,
  Page,
  Text
};

const Kickflip = {
  Text
};

export default Kickflip;
