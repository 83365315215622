import React, { useState } from 'react';

import {
  View,
  TextInput,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Text,
  Heading
} from '../../components';

import { BASE_URL } from '../../.env';

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const setSessionUser = user => (
  sessionStorage.setItem('user', JSON.stringify(user))
);

const SignIn = () => {
  const isMobile = useWindowDimensions().width < 900;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = getSessionUser();

  const onPressSignUp = () => (
    window.location.href = '/signup'
  );

  const onPressSignIn = async () => {
    if (!/^[a-z0-9_\-.]{1,64}@[a-z0-9_\-.]{1,64}$/i.test(email)) {
      alert('Invalid email address format.');

      return;
    }

    if (!/^.{6,64}$/.test(password)) {
      alert('Invalid password format.');

      return;
    }

    const response = await fetch(
      `${BASE_URL}/signin`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        setSessionUser(result.user);

        window.location.href = '/shop';
      }
    }
  };

  if (user?.userData) {
    window.location.href = `/${user.userData.displayName}`;

    return <Text>Redirecting...</Text>;
  }

  return (
    <View style={{
      marginTop: '2rem',
      marginBottom: '2rem',
      backgroundColor: 'black',
      display: isMobile ? 'block' : 'flex',
      flexDirection: 'row'
    }}>
      <View style={{
        flex: 1,
        margin: '3rem'
      }}>
        <View>
          <Heading
            secondary
            value="Sign In"
            style={{
              fontSize: '3.4em'
            }}
          />
          <Text secondary style={{
            fontSize: '1.25em'
          }}>
            Sign in to your account to buy and sell shoes.
          </Text>
        </View>
      </View>
      <View style={{
        flex: 1,
        margin: '3rem'
      }}>
        <View>
          <TextInput
            type="email"
            placeholder="Email address"
            onChangeText={value => setEmail(value)}
            value={email}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <View>
          <TextInput
            secureTextEntry
            type="password"
            placeholder="Password"
            onChangeText={value => setPassword(value)}
            value={password}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <Button
          secondary
          onPress={onPressSignIn}
          style={{
            marginTop: '1rem',
            boxShadow: '0.3rem 0.3rem 0 #ccc'
          }}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text>Sign In</Text>
              <FontAwesomeIcon icon={faArrowRight} />
            </View>
          }
        />
        <Button
          onPress={onPressSignUp}
          style={{ marginTop: '1rem' }}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text secondary>Or, sign up</Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: 'white' }}
              />
            </View>
          }
        />
      </View>
    </View>
  );
};

export default SignIn;
