import React, { useState, useEffect } from 'react';

import {
  Image,
  View,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Text,
  Heading,
  Listing,
  Listings,
  Note,
  Loading
} from '../../components';

import { SignUp } from '..';

import { BASE_URL } from '../../.env';

const FOOT = {
  'L': 'Left',
  'R': 'Right'
};

const Home = () => {
  const isMobile = useWindowDimensions().width < 900;
  const [featured, setFeatured] = useState();

  useEffect(() => {
    const fetchFeatured = async () => {
      const response = await fetch(`${BASE_URL}/listings`);

      if (response?.ok) {
        const result = await response.json();

        setFeatured(result
          .filter(({ buyer }) => !buyer)
          .slice(0, 4)
        );
      }
    };

    fetchFeatured();
  }, []);

  const onPressProduct = productId => () => (
    window.location.href = `/product/${productId}`
  );

  const onPressShop = () => (
    window.location.href = '/shop'
  );

  if (!featured) {
    return <Loading />;
  }

  if (!featured[0]) {
    console.warn('No available listings.');

    return <View />;
  }

  const {
    id,
    gallery = [],
    condition,
    brand,
    product,
    size,
    foot
  } = featured[0];

  return (
    <View>
      <View style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        marginTop: '5rem'
      }}>
        <View>
          <Listing
            id={id}
            photo={gallery[0]}
            cta={(
              <>
                <Button
                  onPress={onPressProduct(id)}
                  value={(
                    <View style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      <Text>View product</Text>
                      <Text
                        small
                        style={{
                          textTransform: 'uppercase',
                          color: '#777'
                        }}>
                          {condition === 'New' ? 'New!' : ''}
                        </Text>
                    </View>
                  )}
                  secondary
                />
                <Button
                  onPress={onPressProduct(id)}
                  value={`${brand} ${product} (${FOOT[foot]} Shoe) - Size ${size}`}
                />
              </>
            )}
          />
          {!isMobile && <View style={{
            display: 'flex',
            marginTop: '3rem'
          }}>
            <Heading
              value="FL"
              style={{
                fontSize: '17em',
                lineHeight: '1em'
              }}
            />
            <Heading
              value="IP"
              style={{
                fontSize: '17em',
                lineHeight: '.6em'
              }}
            />
          </View>}
        </View>
        <View style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          marginLeft: isMobile ? 0 : '5rem',
          marginTop: isMobile ? '4rem': 0
        }}>
          <Note style={{
            fontSize: '1.3em',
            fontWeight: '600'
          }}>
            Kickflip is the social marketplace app where the next generation of skaters go to replace their worn out shoes. Active buyers and sellers all over the United States connect in the app, shaping a new kind of thrift economy that makes footwear more inclusive and less wasteful.
          </Note>
          <Image
            style={{
              width: '100%',
              height: '360px',
              borderRadius: '1rem',
              marginTop: '4rem',
              marginBottom: '4rem'
            }}
            source={{ uri: '/img/shoes.jpg' }}
          />
          <Note>
            Do you skate? Does one shoe usually wear out faster than the other? On Kickflip, you can sell a right shoe, buy a left one, even mix and match styles & colors. Whether you're looking to replace a shoe or just make some extra cash, we're here to help you flip your kicks!
          </Note>
          <Image
            style={{
              width: '100%',
              height: '232px',
              resizeMode: 'contain',
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
            source={{ uri: '/img/shoe.png' }}
          />
        </View>
      </View>
      <Heading value="Kicks" />
      <Listings listings={featured.slice(1)} />
      <SignUp />
      <View style={{
        backgroundImage: 'url("/img/skateboarder.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'left center'
      }}>
        <View style={{
          backgroundColor: 'white',
          marginTop: '3rem',
          marginRight: '3rem',
          marginLeft: isMobile ? '3em' : 'calc(50% + 3rem)',
          marginBottom: '3rem',
          padding: '3rem'
        }}>
          <Heading
            value="Shoes from top brands at discounted prices."
            style={{
              fontSize: isMobile ? '2em' : '3.4em',
              lineHeight: '1.05em'
            }}
          />
          <Note style={{
            fontSize: '1.2em',
            marginTop: '2rem',
            marginBottom: '2rem',
            width: '100%'
          }}>
            All shoes on the platform are sold at a fixed price, depending on the shoe's condition: "Good" ($15), "Great" ($20), and "New" ($25).
          </Note>
          <Button
            onPress={onPressShop}
            value={
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Text secondary>Shop shoes</Text>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: 'white' }}
                />
              </View>
            }
          />
        </View>
      </View>
    </View>
  );
};

export default Home;
