import React from 'react';
import { View, useWindowDimensions } from 'react-native';

import {
  Button,
  Text,
  Listing,
  Loading
} from '../../components';

const FOOT = {
  'L': 'Left',
  'R': 'Right'
};

const LISTINGS_STYLES = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridAutoRows: '1fr',
  gridGap: '2rem'
};

const MOBILE_LISTING_STYLES = {
  display: 'block'
};

const Listings = ({ listings }) => {
  const isMobile = useWindowDimensions().width < 900;

  const onPressProduct = productId => () => (
    window.location.href = `/product/${productId}`
  );

  return !listings ? <Loading /> : (
    <View style={isMobile ? MOBILE_LISTING_STYLES : LISTINGS_STYLES}>
      {listings.map(({
        id,
        condition,
        gallery = [],
        brand,
        product,
        foot,
        size,
        buyer = false
      }) => (
        <Listing
          key={id}
          id={id}
          photo={gallery[0]}
          buyer={buyer}
          cta={(
            <>
              <Button
                onPress={onPressProduct(id)}
                value={(
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <Text>View product</Text>
                    <Text
                      small
                      style={{
                        textTransform: 'uppercase',
                        color: '#777'
                      }}>
                        {condition === 'New' ? 'New!' : ''}
                      </Text>
                  </View>
                )}
                secondary
              />
              <Button
                onPress={onPressProduct(id)}
                value={`${brand} ${product} (${FOOT[foot]} Shoe) - Size ${size}`}
              />
            </>
          )}
        />
      ))}
    </View>
  );
};

export default Listings;
