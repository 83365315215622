import React from 'react';

import Text from '../Text';

const Note = ({
  style = {},
  children = '',

  ...props
}) => (
  <Text
    {...props}

    style={{
      borderLeftWidth: '.8rem',
      marginTop: '.5rem',
      marginBottom: '.5rem',
      paddingLeft: '.5rem',
      fontSize: '1.2em',
      width: '75%',

      ...style
    }}
  >
    {children}
  </Text>
);

export default Note;
