import {
  Image,
  View,
  useWindowDimensions
} from 'react-native';

import {
  Button,
  Heading,
  Text,
  Link,
  Note
} from '../../components';

const DESCRIPTIONS = {
  'Good': 'This shoe is in good condition. There may be significant wear and tear to the exterior of the shoe, but it has its original shape, and there are no holes in the sole or toe. Look at the photos before buying. Shoelaces may or may not be included.',
  'Great': 'This shoe is in great condition. There may be minor wear to the exterior of the shoe. Shoelaces may or may not be included.',
  'New': 'This shoe is new. It has never been worn and comes with the original shoelaces.'
};

const LABEL_STYLE = {
  fontWeight: '500',
  verticalAlign: 'middle',
  marginLeft: '.5rem'
};

const FOOT = {
  L: 'Left',
  R: 'Right'
};

const Product = ({
  brand,
  product,
  seller,
  rating = 0,
  gallery = [],
  condition,
  price,
  shipping,
  foot,
  size,
  cta,
  onPressRemove = null
}) => {
  const isMobile = useWindowDimensions().width < 900;

  return (
    <View style={{
      backgroundColor: 'white',
      marginTop: '1rem',
      marginBottom: '1rem',
      padding: '2rem',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row'
    }}>
      {onPressRemove && <View style={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <Button
          secondary
          onPress={onPressRemove}
          value="✕"
        />
      </View>}
      <View>
        <View style={{
          width: isMobile ? '100%' : '12rem',
          height: isMobile ? '22rem' : '15rem',
          backgroundColor: '#ccc',
          boxShadow: '.3rem .3rem 0 black'
        }}>
          <Image
            source={{
              uri: gallery[0]
            }}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </View>
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Text style={{
            backgroundColor: 'black',
            color: 'white',
            margin: '1rem',
            marginLeft: 0,
            padding: '1rem',
            fontWeight: '600'
          }}>{FOOT[foot]}</Text>
          <Text style={{
            backgroundColor: 'black',
            color: 'white',
            margin: '1rem',
            marginRight: 0,
            padding: '1rem',
            fontWeight: '600'
          }}>Size {size}</Text>
        </View>
      </View>
      <View style={{
        marginLeft: isMobile ? 0 : '2rem',
        width: isMobile ? '100%' : 'calc(100% - 12rem)'
      }}>
        <Heading
          value={`${brand} ${product}`}
          style={{
            marginTop: isMobile ? '2rem' : 0,
            marginBottom: 0
          }}
        />
        <Text>
          by&nbsp;
          <Link href={`/${seller}`}>@{seller}</Link>
          <Text style={{
            marginLeft: '.5rem'
          }}>
            {new Array(5).fill('★').map((star, index) => (
              <Text key={index} style={{
                opacity: index < rating ? 1 : .3
              }}>
                {star}
              </Text>
            ))}
            {rating === 0 && <Text style={LABEL_STYLE}>
              No rating
            </Text>}
            {rating === 5 && <Text style={LABEL_STYLE}>
              5 stars!
            </Text>}
          </Text>
        </Text>
        <View style={{
          marginTop: '2rem',
          marginBottom: '2rem'
        }}>
          <Note style={{
            ...(isMobile
              ? {
                width: '100%'
              }
              : {}
            )
          }}>
            {DESCRIPTIONS[condition]}
          </Note>
          <View style={{
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%'
          }}>
            <Text style={{
              marginTop: '2rem',
              marginRight: isMobile ? '1rem' : '3rem',
              fontSize: '1.2em'
            }}>Price: ${price.toFixed(2)}</Text>
            {shipping && <Text style={{
              marginTop: '1rem',
              marginRight: isMobile ? '1rem' : '3rem',
              fontSize: '1.2em'
            }}>Shipping: ${shipping.toFixed(2)}</Text>}
          </View>
        </View>
        {cta}
      </View>
    </View>
  );
};

export default Product;
