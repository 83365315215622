import { StyleSheet } from 'react-native';

const BASE_PAGE_STYLES = {
  backgroundColor: 'white',
  width: '100%',
  minHeight: '100%',
  minWidth: '900px',
  paddingBottom: '6rem'
};

const BACKGROUND_PAGE_STYLES = {
  ...BASE_PAGE_STYLES,

  backgroundImage: 'url("/img/background.jpg")',
  backgroundPositionX: 'center',
  backgroundPositionY: 'top',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
};

const styles = StyleSheet.create({
  blank: BASE_PAGE_STYLES,

  mobileBlank: {
    ...BASE_PAGE_STYLES,

    minWidth: 0
  },

  background: BACKGROUND_PAGE_STYLES,

  mobileBackground: {
    ...BACKGROUND_PAGE_STYLES,

    minWidth: 0
  }
});

export default styles;
