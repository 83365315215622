import React from 'react';

import { Pressable, Text } from 'react-native';

import styles from './styles';

const Button = ({
  style = {},
  value = '',
  onPress = () => {},
  secondary = false,
  center = false,
  disabled,

  ...props
}) => {
  const className = secondary ? 'secondary' : 'primary';
  const textClassName = `text-${className}`;

  return (
    <Pressable
      {...props}

      style={{
        ...style,

        ...styles[className],

        ...(disabled ? {
          opacity: .2,
          pointerEvents: 'none'
        } : {}),

        ...(center ? {

            width: '20rem',
            maxWidth: '100%',
            margin: '1rem',
            marginLeft: 'auto',
            marginRight: 'auto'
        } : {})
      }}

      onPress={onPress}
    >
      <Text style={styles[textClassName]}>
        {value}
      </Text>
    </Pressable>
  );
};

export default Button;
