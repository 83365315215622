import React, { useState, useEffect } from 'react';
import { View } from 'react-native';

import {
  PageHeading,
  Listings,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const Shop = () => {
  const [listings, setListings] = useState();

  useEffect(() => {
    const fetchListings = async () => {
      const response = await fetch(`${BASE_URL}/listings`);

      if (response?.ok) {
        const result = await response.json();

        setListings(result.filter(({ buyer }) => !buyer));
      }
    };

    fetchListings();
  }, []);

  return !listings ? <Loading /> : (
    <View>
      <PageHeading value="Shop" />
      <Listings listings={listings} />
    </View>
  );
};

export default Shop;
