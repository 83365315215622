import {
  Text,
  View,
  useWindowDimensions
} from 'react-native';

import styles from './styles';

const Heading = ({
  style = {},
  value = '',
  secondary = false,
  center = false,
  banner = false,
  logo = false,

  ...props
}) => {
  const isMobile = useWindowDimensions().width < 900;

  const className = banner
    ? isMobile
     ? 'mobileBanner'
     : 'banner'
    : logo
      ? isMobile
        ? 'mobileLogo'
        : 'logo'
      : secondary
        ? 'secondary'
        : 'primary';

  return (
    <View style={{
      width: center || banner || logo
        ? '100%'
        : 'auto'
      }
    }>
      <Text
        {...props}

        style={{
          ...styles[className],

          ...(center
            ? {
              width: '100%',
              textAlign: 'center'
            }
            : {}
          ),

          ...style
        }}
      >
        {value}
      </Text>
    </View>
  )
};

export default Heading;
