import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import { Container, Page } from './components';

import {
  Home,
  Address,
  Cart,
  FAQ,
  Fern,
  Messages,
  Privacy,
  Product,
  Profile,
  Publish,
  Shop,
  SignIn,
  SignUp,
  Thread
} from './pages';

const App = () => (
  <Router>
    <Page
      background={!window.location.href.match(/messages/)}
      banner={window.location.pathname === '/'}
    >
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/address/:redirect" element={<Address />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/fern/:redirect" element={<Fern />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:username" element={<Thread />} />
          <Route path="/product/:productId" element={<Product />} />
          <Route path="/:username" element={<Profile />} />
          <Route path="/publish" element={<Publish />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/:username" element={<Profile />} />
        </Routes>
      </Container>
    </Page>
  </Router>
);

export default App;
