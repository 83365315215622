import React, { useState, useEffect } from 'react';
import { Image, View } from 'react-native';

import {
  Link,
  PageHeading,
  Text,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Messages = () => {
  const user = getSessionUser();
  const [messagePreviews, setMessagePreviews] = useState();

  useEffect(() => {
    const fetchMessages = async () => {
      const response = await fetch(
        `${BASE_URL}/get-messages`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: user.username,
            token: user.token
          })
        }
      );

      if (response?.ok) {
        const result = await response.json();

        if (result) {
          setMessagePreviews(Object.keys(result).map(recipient => {
            const latestMessage = result[recipient].pop();

            const firstRecipientMessage = (
              result[recipient].reverse().find(({ sender }) => sender === recipient)
            ) || { photo: '' };

            return {
              recipient,
              photo: firstRecipientMessage.photo,
              unread: 0,
              preview: latestMessage.text.slice(0, 100)
            };
          }));
        }
      }
    };

    fetchMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user?.userData) {
    window.location.href = '/signin';

    return <Text>Redirecting...</Text>;
  }

  return !messagePreviews ? <Loading /> : (
    <>
      <PageHeading value="Messages" />
      <View>
        {(messagePreviews || []).map(({
          recipient,
          photo,
          unread,
          preview
        }) => (
          <Link
            key={recipient}
            href={`/messages/${recipient}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              // opacity: unread ? 1 : .5,
              opacity: unread ? 1 : 1,
              textDecoration: 'none'
            }}
          >
            <View style={{
              width: '4rem',
              height: '4rem',
              borderRadius: '100vw',
              margin: '1rem'
            }}>
              {unread ? <View style={{
                background: '#c52b0c',
                width: '1.6rem',
                height: '1.6rem',
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100vw'
              }}>
                <Text secondary style={{
                  fontSize: '1.3em',
                  fontWeight: '800'
                }}>{unread}</Text>
              </View> : null}
              {photo ? <Image
                source={{ uri: photo }}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '100vw'
                }}
              /> : <View
              style={{
                backgroundColor: 'black',
                width: '100%',
                height: '100%',
                borderRadius: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Text
                  secondary
                  style={{
                    textAlign: 'center',
                    fontSize: '3em'
                  }}>
                  {recipient.charAt(0).toUpperCase()}
                </Text>
              </View>}
            </View>
            <View style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>
              <Text style={{
                fontSize: '1.7em',
                fontWeight: unread ? '800' : '400'
              }}>{recipient}</Text>
              <Text style={{
                fontSize: '1.4em',
                fontWeight: unread ? '800' : '400'
              }}>
                {preview}
              </Text>
            </View>
          </Link>
        ))}
      </View>
    </>
  );
};

export default Messages;
