import { StyleSheet } from 'react-native';

const BASE_BUTTON_STYLES = {
  padding: '1rem'
};

const BASE_TEXT_STYLES = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const styles = StyleSheet.create({
  primary: {
    ...BASE_BUTTON_STYLES,

    backgroundColor: 'black'
  },
  secondary: {
    ...BASE_BUTTON_STYLES,
    
    backgroundColor: 'white'
  },
  'text-primary': {
    ...BASE_TEXT_STYLES,

    color: 'white',
    fontWeight: '500',
    fontFamily: 'Open Sans, sans-serif'
  },
  'text-secondary': {
    ...BASE_TEXT_STYLES,
    
    color: 'black',
    fontWeight: '500',
    fontFamily: 'Open Sans, sans-serif'
  }
});

export default styles;
