import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { View, useWindowDimensions } from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Link,
  Text,
  PageHeading,
  Product as ProductComponent,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const PRICES = {
  'Good': '15.00',
  'Great': '20.00',
  'New': '25.00'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Product = () => {
  const isMobile = useWindowDimensions().width < 900;
  const user = getSessionUser();
  const { pathname } = useLocation();
  const [product, setProduct] = useState();
  const [isInCart, setIsInCart] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchCartItem = async productId => {
    const response = await fetch(
      `${BASE_URL}/get-user`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.username,
          token: user.token
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.cart) {
        setIsInCart(result?.cart?.find(({ id }) => id === productId));
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (pathname) {
      const id = pathname.split('/').pop();

      const fetchProduct = async () => {
        if (!id) return;

        const response = await fetch(
          `${BASE_URL}/listing`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              username: user?.username,
              token: user?.token,
              displayName: user?.userData?.displayName,
              listingId: id
            })
          }
        );

        if (response?.ok) {
          const result = await response.json();

          setProduct(result);
          fetchCartItem(result.id);
        }
      };

      fetchProduct();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onPressAddToCart = async () => {
    if (!user?.userData) {
      window.location.href = '/signin';

      return;
    }

    if (!product?.id) return;

    setIsInCart(true);

    const { username, token } = user;

    const response = await fetch(
      `${BASE_URL}/cart`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: username,
          token,
          listingId: product.id,
          seller: product.seller
        })
      }
    );

    if (response?.ok) {
      fetchCartItem(product.id);
    }
  };

  const onPressShop = () => (
    window.location.href = '/shop'
  );

  const isSellerUser = user?.userData && (
    product?.seller === user.userData.displayName
  );

  return isLoading ? <Loading /> : (
    <>
      <Link href="/shop" style={{ textDecoration: 'none' }}>
        <PageHeading value="Shop" />
      </Link>
      <ProductComponent
        {...product}

        cta={(
          <View style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginRight: isMobile ? 0 : '2rem'
          }}>
            {!isSellerUser && !product.buyer && <Button
              disabled={isInCart}
              style={{
                width: isMobile ? '100%' : '20rem',
                maxWidth: '100%'
              }}
              onPress={onPressAddToCart}
              value={
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Text secondary>{isInCart ? '✓ Added to cart' : 'Add to cart'}</Text>
                  <Text secondary>${PRICES[product.condition]}</Text>
                </View>
              }
            />}
            <Button
              secondary
              style={{
                width: isMobile ? '100%' : '20rem',
                maxWidth: '100%'
              }}
              onPress={onPressShop}
              value={
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <Text>Continue shopping</Text>
                  <FontAwesomeIcon icon={faArrowRight} />
                </View>
              }
            />
          </View>
        )}
      />
    </>
  );
};

export default Product;
