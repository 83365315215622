import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Image,
  Text,
  View,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import {
  faEnvelope,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

import Kickflip, {
  Button,
  PageHeading,
  Note,
  Link,
  Listings,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const LABEL_STYLE = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '.25em',
  fontWeight: '500',
  verticalAlign: 'middle',
  marginLeft: '.5rem'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Profile = () => {
  const isMobile = useWindowDimensions().width < 900;
  const { pathname } = useLocation();
  const [displayName, setDisplayName] = useState();
  const [profileUser, setProfileUser] = useState();
  const [isRateable, setIsRateable] = useState(false);
  const user = getSessionUser();

  useEffect(() => {
    if (pathname) {
      const profileName = pathname.split('/').pop();

      setDisplayName(profileName);

      const fetchProfileUser = async () => {
        const response = await fetch(
          `${BASE_URL}/get-user`,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              displayName: profileName,
              token: user.token
            })
          }
        );

        if (response?.ok) {
          const result = await response.json();

          if (result?.listings) {
            setProfileUser(result);

            if (user?.userData) {
              setIsRateable(
                result.listings.some(({ buyer }) => buyer === user.userData.displayName)
              );
            }
          }
        }
      };

      fetchProfileUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onPressNewListing = () => (
    window.location.href = '/publish'
  );

  const onRate = rating => async () => {
    const response = await fetch(
      `${BASE_URL}/rating`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user.username,
          token: user.token,
          seller: displayName,
          rating
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        alert(`Thanks for rating ${displayName}!`);

        window.location.reload();
      }
    }
  };

  const isProfileUser = (
    profileUser && user?.userData &&
    (user.userData.displayName === profileUser.displayName)
  );

  return !profileUser ? <Loading /> : (
    <>
      <View>
        <View>
          <PageHeading style={{
            width: '100%'
          }} value={
            <View style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
              <Text>{`@${profileUser.displayName}`}</Text>
              <Text style={{
                marginLeft: isMobile ? 0 : '1rem'
              }}>
                {new Array(5).fill('★').map((star, index) => (
                  <Text
                    key={index}
                    onPress={isRateable ? onRate(index + 1) : null}
                    style={{
                      opacity: index < profileUser.rating ? 1 : .3,
                      cursor: isRateable ? 'pointer' : 'default'
                    }}
                  >
                    {star}
                  </Text>
                ))}
                {profileUser.rating === 0 && <Text style={LABEL_STYLE}>
                  No rating
                </Text>}
                {profileUser.rating === 5 && <Text style={LABEL_STYLE}>
                  5 stars!
                </Text>}
              </Text>
              {isProfileUser ? (
                <Button
                  onPress={onPressNewListing}
                  value={
                    <View style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Text secondary>List a shoe</Text>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ color: 'white' }}
                      />
                    </View>
                  }
                  style={{
                    width: '10rem',
                    fontSize: '.3em',
                    position: 'absolute',
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    ...(isMobile
                      ? {
                        position: 'static',
                        width: '100%',
                        marginTop: '2rem',
                        marginBottom: '2rem'
                      }
                      : {}
                    )
                  }}
                />
              ) : (
              <View style={{
                position: 'absolute',
                right: 0
              }}>
                <Link href={`/messages/${profileUser.displayName}`}>
                  <FontAwesomeIcon icon={faEnvelope} size={30} />
                </Link>
              </View>
            )}
            </View>
          } />
        </View>
        <View style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '3rem'
        }}>
        {profileUser.photo ? <Image
            source={{ uri: profileUser.photo }}
            style={{
              backgroundColor: '#ccc',
              width: '6rem',
              height: '6rem',
              borderRadius: '100vw',
              margin: '1rem'
            }}
          /> : <View
          style={{
            backgroundColor: 'black',
            width: '4rem',
            height: '4rem',
            borderRadius: '100vw',
            margin: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Kickflip.Text
              secondary
              style={{
                textAlign: 'center',
                fontSize: '3em'
              }}>
              {profileUser.displayName.charAt(0).toUpperCase()}
            </Kickflip.Text>
          </View>}
          <Note style={{ lineBreak: 'anywhere' }}>
            {profileUser.bio}
          </Note>
        </View>
        <Listings listings={profileUser.listings} />
      </View>
    </>
  );
};

export default Profile;
