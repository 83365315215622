import React, { useState } from 'react';

import {
  View,
  TextInput,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import {
  faUser,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Text,
  Heading
} from '../../components';

import { BASE_URL } from '../../.env';

const SignUp = () => {
  const isMobile = useWindowDimensions().width < 900;
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const onPressSignIn = () => (
    window.location.href = '/signin'
  );

  const onPressSignUp = async () => {
    if (password && password2 && password !== password2) {
      alert('Passwords don\'t match.');

      return;
    }

    if (!/^[a-z0-9_\-.]{1,64}$/i.test(displayName)) {
      alert('Invalid display name format.');

      return;
    }

    if (!/^[a-z0-9_\-.]{1,64}@[a-z0-9_\-.]{1,64}$/i.test(email)) {
      alert('Invalid email address format.');

      return;
    }

    if (!/^.{6,64}$/.test(password)) {
      alert('Invalid password format.');

      return;
    }

    const response = await fetch(
      `${BASE_URL}/signup`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          displayName,
          email,
          password
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        alert('Thanks for registering! Sign in to continue using Kickflip.');

        window.location.href = '/signin';
      }

      if (result?.message) {
        alert(result.message);
      }
    }
  };

  return (
    <View style={{
      marginTop: '2rem',
      marginBottom: '2rem',
      backgroundColor: 'black',
      display: isMobile ? 'block' : 'flex',
      flexDirection: 'row'
    }}>
      <View style={{
        flex: 1,
        margin: '3rem'
      }}>
        <View>
          <Heading
            secondary
            value="For sellers"
            style={{
              fontSize: '3.4em'
            }}
          />
          <Text secondary style={{
            fontSize: '1.25em'
          }}>
            Kickflip makes it easy to list products, upload images, print shipping labels, and get paid - all in a single experience on the web or on mobile.
          </Text>
        </View>
        <View>
          <Heading
            secondary
            value="For buyers"
            style={{
              fontSize: '3.4em',
              marginTop: '2rem'
            }}
          />
          <Text secondary style={{
            fontSize: '1.25em'
          }}>
            Kickflip makes it easy to buy just one shoe, whether it's for replacing a worn out skate shoe, as a fashion quirk, or for any other reason. Payments are handled securely by Square (major cards accepted*), and you can track your shoe once it's shipped.
          </Text>
          <Text secondary style={{
            marginTop: '.5rem'
          }}>* Visa, Mastercard, American Express, Discover, JCB, and UnionPay</Text>
        </View>
      </View>
      <View style={{
        flex: 1,
        margin: '3rem'
      }}>
        <View>
          <TextInput
            type="text"
            placeholder="Display name"
            onChangeText={value => setDisplayName(value)}
            value={displayName}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <View>
          <TextInput
            type="email"
            placeholder="Email address"
            onChangeText={value => setEmail(value)}
            value={email}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <View>
          <TextInput
            secureTextEntry
            type="password"
            placeholder="Password"
            onChangeText={value => setPassword(value)}
            value={password}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <View>
          <TextInput
            secureTextEntry
            type="password"
            placeholder="Repeat password"
            onChangeText={value => setPassword2(value)}
            value={password2}
            style={{
              color: 'white',
              border: 'none',
              borderBottom: 'solid 1px white',
              lineHeight: '3em',
              marginTop: '1rem',
              outline: 'none'
            }}
          />
        </View>
        <Button
          secondary
          style={{
            marginTop: '1rem',
            boxShadow: '0.3rem 0.3rem 0 #ccc'
          }}
          onPress={onPressSignUp}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text>Create account</Text>
              <FontAwesomeIcon icon={faUser} />
            </View>
          }
        />
        <Button
          onPress={onPressSignIn}
          style={{ marginTop: '1rem' }}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text secondary>Or, sign in</Text>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: 'white' }}
              />
            </View>
          }
        />
      </View>
    </View>
  );
};

export default SignUp;
