const SQUARE_APP_ID = 'sq0idp-rT4cOK5_kccyWm8GYOnS0g';
const SQUARE_LOCATION_ID = 'LZQA0FV1D50R3';
const BASE_URL = 'https://www.exactchange.network/kickflip';
const FERN_URL = 'https://www.exactchange.network/fern';

export {
  SQUARE_APP_ID,
  SQUARE_LOCATION_ID,
  BASE_URL,
  FERN_URL
};
