import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Image,
  TextInput,
  View,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Link,
  PageHeading,
  Text
} from '../../components';

import { BASE_URL } from '../../.env';

const MAX_CHARACTER_COUNT = 280;
const MESSAGE_TICK_INTERVAL = 5000;

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Thread = () => {
  const isMobile = useWindowDimensions().width < 900;
  const { pathname } = useLocation();
  const [value, setValue] = useState('');
  const [thread, setThread] = useState();
  const user = getSessionUser();

  const fetchMessages = async () => {
    const response = await fetch(
      `${BASE_URL}/get-messages`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.username,
          token: user.token
        })
      }
    );

    if (response?.ok) {
      const result = await response.json();

      if (result) {
        const displayName = pathname.split('/').pop();

        const messageList = result[displayName];

        if (messageList) {
          setThread(messageList);
        }
      }
    }
  };

  useEffect(() => {
    if (pathname) {
      fetchMessages();
    }

    requestAnimationFrame(() => {
      document.querySelector('#root').scrollTo(0, document.querySelector('#root').scrollHeight);
    });

    const pseudoSocketId = setInterval(fetchMessages, MESSAGE_TICK_INTERVAL);

    return () => clearInterval(pseudoSocketId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onChangeText= text => setValue(text);

  const onKeyPress = event=> {
    if (event.keyCode === 13) {
      event.preventDefault();
      onPressSend();
    }
  };

  const onPressSend = async () => {
    if (!value || !pathname) return;

    const response = await fetch(
      `${BASE_URL}/message`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: user.username,
          token: user.token,
          recipient: pathname.split('/').pop(),
          text: value
        })
      }
    );

    setValue('');

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        fetchMessages();

        return;
      }
    }
  };

  const onPressBack = () => {
    window.location.href = '/messages';
  };

  if (!user?.userData) {
    window.location.href = '/signin';

    return <Text>Redirecting...</Text>;
  }

  const userDisplayName = user.userData.displayName;
  const recipient = pathname.split('/').pop();

  return (
    <View>
      <PageHeading value={`@${recipient}`} />
      {(thread || []).map(({
        id,
        sender,
        photo,
        text,
        timestamp
      }) => {
        const isUserSender = sender === userDisplayName;

        const timezoneOffset = new Date().getTimezoneOffset() * 60000;

        const localTimestamp = new Date(new Date(timestamp) - timezoneOffset)
          .toISOString()
          .slice(0, -1);

        const secondsAgo = (
          (new Date() - new Date(localTimestamp)) / 1000
        ) << 0;

        const minutesAgo = secondsAgo > 60 && (
          secondsAgo / 60
        ) << 0;

        const hoursAgo = minutesAgo > 60 && (
          minutesAgo / 60
        ) << 0;

        const daysAgo = hoursAgo > 24 && (
          hoursAgo / 24
        ) << 0;

        const isDays = Boolean(daysAgo);
        const isHours = Boolean(hoursAgo);
        const isMinutes = Boolean(minutesAgo);

        const displayTimestamp = `${
          isDays
            ? daysAgo
            : isHours
             ? hoursAgo
             : isMinutes
              ? minutesAgo
              : 'Less than a'
        } ${
          isDays
            ? `day${daysAgo === 1 ? '' : 's'}`
            : isHours
             ? `hour${hoursAgo === 1 ? '' : 's'}`
             : isMinutes
              ? `minute${minutesAgo === 1 ? '' : 's'}`
              : 'minute'
        } ago`;

        return (
          <React.Fragment key={id}>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              direction: isUserSender
                ? 'rtl'
                : 'ltr',
              width: isMobile ? '100%': 'auto',
              marginTop: '1rem'
            }}>
              {!isMobile && <Link href={isUserSender
                ? `/${user.userData.displayName}`
                : `/${sender}`
              }>
                {photo ? <Image
                  source={{ uri: photo }}
                  style={{
                    width: '4rem',
                    height: '4rem',
                    borderRadius: '100vw',
                    margin: '1rem'
                  }}
                /> : <View
                style={{
                  backgroundColor: 'black',
                  width: '4rem',
                  height: '4rem',
                  borderRadius: '100vw',
                  margin: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Text
                    secondary
                    style={{
                      textAlign: 'center',
                      fontSize: '3em'
                    }}>
                    {(isUserSender
                      ? user.userData.displayName
                      : sender
                    ).charAt(0).toUpperCase()}
                  </Text>
                </View>}
              </Link>}
              <View style={{
                fontSize: '1.4em',
                backgroundColor: isUserSender
                  ? '#ccc'
                  : 'transparent',
                padding: '1rem',
                maxWidth: isMobile ? '100%' : 'calc(100% - 15rem)'
              }}>
                <Text>{text}</Text>
                <Text small
                  style={{
                    opacity: .4,
                    fontWeight: '400',
                    textAlign: isUserSender
                    ? 'right'
                    : 'left',
                    marginTop: '.5rem',
                    marginBottom: '.5rem',
                    fontSize: '.75em'
                  }}>
                    {displayTimestamp}
                  </Text>
              </View>
            </View>
          </React.Fragment>
        );
      })}
      <Text style={{ marginLeft: '1rem' }}>
        Send&nbsp;
        <Text style={{ fontWeight: '800' }}>@{recipient}</Text>
        &nbsp;a message:
      </Text>
      <View style={{ position: 'relative' }}>
        <TextInput
          multiline
          numberOfLines={5}
          style={{
            backgroundColor: 'black',
            color: 'white',
            margin: '1rem',
            padding: '1rem',
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '1.3em'
          }}
          onChangeText={onChangeText}
          onKeyPress={onKeyPress}
          value={value}
        />
        <Text secondary style={{
          position: 'absolute',
          bottom: '1.5rem',
          right: '1.5rem',
          fontSize: '1.3em',
          opacity: .5
        }}>{MAX_CHARACTER_COUNT - value.length}</Text>
      </View>
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Button
          secondary
          onPress={onPressBack}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ color: 'black' }}
              />
              <Text>Messages</Text>
            </View>
          }
          style={{
            margin: '1rem',
            marginTop: 0,
            width: '8rem'
          }}
        />
        <Button
          onPress={onPressSend}
          value={
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Text secondary>Send</Text>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ color: 'white' }}
              />
            </View>
          }
          style={{
            margin: '1rem',
            marginTop: 0,
            width: '8rem'
          }}
        />
      </View>
    </View>
  );
};

export default Thread;
