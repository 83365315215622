import React from 'react';

import { Text as NativeText } from 'react-native';

import styles from './styles';

const Text = ({
  style = {},
  children = '', 
  onPress = () => {},
  secondary = false,
  small = false,
  center = false,

  ...props
}) => {
  let className = secondary ? 'secondary' : 'primary';

  if (small) {
    className = 'small';
  }

  return (
    <NativeText 
      {...props} 

      style={{
        ...styles[className],

        ...style,

        ...(center 
          ? { 
            width: '100%', 
            textAlign: 'center' 
          } 
          : {}
        )
      }}
    >
      {children}
    </NativeText>
  );
};

export default Text;
