import React, { useState, useEffect } from 'react';

import {
  Image,
  Picker,
  View,
  useWindowDimensions
} from 'react-native';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
  Button,
  Text,
  ImagePicker,
  Heading,
  Loading
} from '../../components';

import { BASE_URL } from '../../.env';

const CONDITIONS = {
  GOOD: 'Good',
  GREAT: 'Great',
  NEW: 'New'
};

const FOOT = {
  LEFT: 'L',
  RIGHT: 'R'
};

const BRANDS = [
  'Adidas',
  'Adio',
  'Airwalk',
  'Axion',
  'Circa',
  'Converse',
  'DC Shoes',
  'Dekline',
  'DVS',
  'Duffs',
  'Emerica',
  'Element',
  'éS',
  'Etnies',
  'Fallen',
  'Globe',
  'Gravis',
  'Habitat',
  'HUF',
  'I-Path',
  'Jack & Jones',
  'Lakai',
  'New Balance',
  'Nike',
  'Osiris',
  'Puma',
  'Reebok',
  'Vans',
  'World Industries',
  'Zoo York'
];

const SIZES = [
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '12.5',
  '13',
  '13.5',
  '14',
  '14.5',
  '15'
];

const PRODUCTS = {
  'Adidas': [
    'Aloha Super 80\'s',
    'Busenitz',
    'Busenitz Vintage',
    'Busenitz Vulc II',
    'Gazelle',
    'Grand Court',
    'Matchbreak',
    'Match Break Super',
    'Superstar',
    'Tyshawn',
    'Tyshawn Low',
    'Forum 84 Low',
    'Samba',
    'Superskate'
  ],
  'Adio': [
    'Avery',
    'Betsey',
    'Crane',
    'Drayton',
    'Eugene',
    'Hamilton',
    'Kenny'
  ],
  'Airwalk': [
    'Brock',
    'Gusto',
    'Legacee',
    'Neptune'
  ],
  'Axion': [
    'Banx',
    'Complex High',
    'Genesis',
    'Genesis Mid',
    'Hampton',
    'Hyphy',
    'Lowphy',
    'Majik',
    'Official',
    'Pallas',
    'Rue'
  ],
  'C1RCA': [
    '99 Vulc',
    '205 Vulc',
    'AL50'
  ],
  'Converse': [
    'Chuck 70 Low',
    'Chuck 70 High',
    'Chuck Taylor All Star Low',
    'Chuck Taylor All Star High',
    'Chuck Taylor All Star Pro Low',
    'Chuck Taylor All Star Pro High',
    'Louie Lopez Pro',
    'Louie Lopez Pro Mid',
    'Louie Lopez Pro Low',
    'One Star Slip Pro',
    'One Star Pro',
    'One Star Pro Low'
  ],
  'DC Shoes': [
    'Anvil',
    'Central',
    'Court Graffik',
    'Crisis 2',
    'Crisis 2 S',
    'Gaveler',
    'Hyde',
    'Hyde S',
    'Kalis Vulc',
    'Kalis Vulc Mid',
    'Kalis Vulc S',
    'Lynx Zero',
    'Lynx Zero S',
    'Manteca 4',
    'Manual',
    'Manual S',
    'Manual High',
    'Manual Slip-On',
    'Stag',
    'Striker',
    'Villain 2',
    'Williams Slim'
  ],
  'Dekline': [
    'Bennett',
    'Bennett 2',
    'Camel Suede',
    'CT Slip On',
    'Dakota',
    'Emmett',
    'Everett',
    'Jaws',
    'Keaton',
    'Mason',
    'Sante Fe',
    'Tim Tim',
    'Wayland'
  ],
  'DVS': [
    'Celsius',
    'Comanche',
    'Comanche 2.0',
    'Devious',
    'Enduro 125',
    'Enduro Heir',
    'Gambol',
    'Honcho',
    'Militia Snow',
    'Tycho'
  ],
  'Duffs': [
    '4130 Low',
    'Casual',
    'Vintage'
  ],
  'Emerica': [
    'Americana',
    'Dickson',
    'Figgy Dose',
    'Figueroa',
    'Gamma',
    'Gamma G6',
    'KSL G6',
    'Low Vulc',
    'Omen Lo',
    'Omen Hi',
    'Pillar',
    'Quentin',
    'Romero Laced',
    'Romero Laced High',
    'Spanky G6',
    'Temple',
    'Tilt G6 Vulc',
    'Wino Cruiser',
    'Wino G6 Slip Cup',
    'Wino G6 Slip-On',
    'Wino Slip-On Youth',
    'Wino Standard'
  ],
  'Element': [
    'Backwoods',
    'Heatley',
    'Heatley 2.0',
    'Passiph',
    'Pitch',
    'Preston 2',
    'Preston 2 Mid',
    'Sneaker',
    'Sawyer',
    'Sawyer Mid',
    'Trainer Low',
    'The Edge Mule',
    'Topaz C3'
  ],
  'éS': [
    'Accel',
    'Accel Explorer',
    'Accel OG',
    'Accel OG Plus',
    'Accel Plus Ever Stitch Eco',
    'Accel Remastered',
    'Accel Slim',
    'Accel Slim Mid',
    'Accel Slim x Swift 1.5',
    'Caspian',
    'Cykle',
    'EOS',
    'Evant',
    'Silo SC',
    'Silo SC Eco',
    'Swift 1.5',
    'Swift 1.5 x Quattro',
    'Symbol',
    'Quattro',
    'Quattro Plus',
    'Quattro x Silo'
  ],
  'Etnies': [
    'Barge',
    'Calli Vulc',
    'Fader',
    'Faze',
    'Jefferson',
    'Kingpin',
    'Lo-Cut CB',
    'Lo-Cut II LS',
    'Josl1n',
    'Joslin',
    'Joslin 2',
    'Joslin Vulc',
    'Marana',
    'Marana Slip XLT',
    'Semenuk Pro',
    'Sultan',
    'Windrow',
    'Windrow Vulc'
  ],
  'Fallen': [
    'Bomber',
    'Bomber C-03',
    'Bomber Vulc',
    'Patriot',
    'Ripper Cole',
    'Rising Sun',
    'T-Gun',
    'The Fiend Sloan',
    'The Goat',
    'The Loc',
    'Tremont',
    'Trooper Cole'
  ],
  'Globe': [
    'Dimension',
    'Dover',
    'Encore 2',
    'Fusion',
    'Liaizon',
    'Los Angered II',
    'Mahalo',
    'Mahalo Plus',
    'Motley II',
    'Motley II Strap',
    'Motley Mid',
    'Sabre',
    'Surplus',
    'Tilt'
  ],
  'Gravis': [
    'Alex',
    'Alex AC',
    'Belfour',
    'Cambridge BCS',
    'Cue',
    'Eddie',
    'Grant',
    'Grant WP',
    'Harvey',
    'Kona JP',
    'Kona LX',
    'Luce',
    'Odjick',
    'Rival',
    'Rival BCS',
    'Ryan',
    'Shanahan',
    'Stevens',
    'Tanker AC ZIP',
    'Tanker AR',
    'Tarmac HC DLX',
    'Tarmac JP',
    'Tarmac TLX'
  ],
  'Habitat': [
    'Austyn',
    'Expo',
    'Garcia',
    'Getz',
    'Guru 2',
    'Ibex',
    'Lark',
    'Moray',
    'Quest',
    'Retro',
    'Surrey'
  ],
  'HUF': [
    'Choice Low-Top',
    'Clive',
    'Cromer',
    'Galaxy',
    'Huf 1 Vulc',
    'Hufnagel 2',
    'Hupper 2',
    'Hupper 2 Lo',
    'Hupper 2 Hi',
    'Lo Ess',
    'Soto'
  ],
  'I-Path': [
    'Adam Alfaro',
    'Bob Burnquist',
    'Canvas',
    'Cats',
    'Grasshopper',
    'Hemp',
    'Hemp Rasta',
    'Honey Brown',
    'Jones 2 Rasta',
    'Leary',
    'Leather',
    'Locust Rasta',
    'Locust Rasta High Top',
    'Rasta Jamaican',
    'Rasta OG',
    'Reed',
    'Reed Mid',
    'Rodriguez',
    'Sativa',
    'Suede',
    'Stash',
    'The Traveler',
    'Vintage',
    'Vintage Low top',
    'Wharf',
    'Yogi'
  ],
  'Jack & Jones': [
    'Slip On'
  ],
  'Lakai': [
    'Atlantic',
    'Brighton',
    'Cambridge',
    'Cambridge Mid',
    'Evo 2.0',
    'Evo 2.0 XLK',
    'Flaco 2',
    'Flaco 2 Mid',
    'Griffin',
    'Manchester',
    'Owen VLK',
    'Oxford',
    'Riley 3',
    'Sheffield',
    'Telford Low',
    'Trudger'
  ],
  'New Balance': [
    '22',
    '212 Pro Court',
    '213 Pro Court',
    '228 Sport',
    '255',
    '272',
    '288 Sport',
    '306 Laceless',
    '425',
    '440',
    '440 High',
    '508',
    'Brandon Westgate 508',
    'Jack Curtin 228 Sport',
    'Jamie Foy 306',
    'Jamie Foy 306 Laceless',
    'Margielyn Didal 272',
    'Tiago Lemos 1010',
    'Tom Knox 440',
    'Tom Knox 440 High'
  ],
  'Nike': [
    'Adversary Premium',
    'Air Force 1 \'07',
    'Air Force 1 Pixel',
    'Air VaporMax Plus',
    'Chron 2',
    'Chron 2 Canvas',
    'BLZR Court',
    'BLZR Court Mid',
    'BLZR Court Mid Premium',
    'Bruin React',
    'Force 58',
    'Heritage Vulc',
    'In-Season',
    'Ishod',
    'Ishod Premium',
    'Nyjah',
    'Nyjah Free 2',
    'Shane',
    'Shane Premium',
    'Verona Slip',
    'Verona Slip Leticia Bufoni',
    'Zoom Blazer Low',
    'Zoom Blazer Low GT',
    'Zoom Blazer Mid',
    'Zoom Blazer Mid',
    'Zoom Blazer Mid Premium'
  ],
  'Osiris': [
    'Bentley VLC',
    'Clone',
    'D3 2001',
    'Graff',
    'NYC 83 Clk',
    'Protocol',
    'Relic',
    'Rize Ultra',
    'Troma Icon'
  ],
  'Puma': [
    'Basket Classic',
    'Basket Mid',
    'Black Fives',
    'Blaze of Glory "Reverse Classics"',
    'BMW M Motorsport Roma',
    'California Casual',
    'City Rider Disruptive',
    'Clyde Core Foil',
    'Court Rider I',
    'Deviate Nitro',
    'Future Rider',
    'GV Special',
    'H.ST.20',
    'Magnify Nitro',
    'MB.01 Galaxy',
    'Mercedes F1 RS-Z',
    'Porsche Legacy',
    'Pure XT Refined',
    'Roma Basic',
    'RS 2.0 Lightsense',
    'RS-X',
    'RS-X Galaxy',
    'RS-X Global Futurism',
    'RS-X Highlighter',
    'RS-X T3CH Overload',
    'RS-Z LTH',
    'Scuderia Ferrari RS-Fast Motorsport',
    'Slipstream Lo Reprise',
    'Smash v2',
    'Speedcat',
    'Speedcat LS',
    'Speedcat OG',
    'Speedcat Shield',
    'Suede Classic',
    'Suede Classic Pastime',
    'Suede Classic XXI',
    'Suede Mid XXI',
    'Wild Rider'
  ],
  'Reebok': [
    'Club C',
    'Club C Double',
    'Club C Double Geo',
    'Club C 85',
    'Club C 85 Vintage',
    'Club C Revenge Vintage',
    'Daniel Moon Club C',
    'Eames Club C 85',
    'Maison Margiela Club C',
    'Victoria Beckham Club C'
  ],
  'Vans': [
    'Authentic',
    'Ave',
    'Berle',
    'BMX Old Skool',
    'Chukka Low',
    'Chukka Low Sidestripe',
    'Chukka Slip-er',
    'Classic Slip-On',
    'Comfycush Authentic',
    'Destruct',
    'Era',
    'Fairlane',
    'Gilbert Crocket',
    'Gilbert Crocket High',
    'Grosso Mid',
    'Half Cab',
    'Half Cab \'92',
    'Kyle Walker',
    'Kyle 2',
    'Old Skool',
    'Old Skool Mule',
    'Old Skool VCU',
    'Mid Slip MTE',
    'Rowan',
    'Rowley',
    'Sk8-Hi',
    'Sk8-Low',
    'Sk8-Mid',
    'Slip-On',
    'Slip-On Checkerboard',
    'Style 36 Decon SF',
    'The Lizzie',
    'Ultrarange EXO',
    'Wayvee'
  ],
  'World Industries': [
    'Bones',
    'Court',
    'Grind Boys',
    'Insane',
    'Primo',
    'Redondo',
    'Ripper',
    'Segundo',
    'Shetler Mid',
    'Showcase',
    'Titan',
    'Varsity',
    'Varsity Mid',
    'Venice',
    'Wallie',
    'Wallie Lace-Up',
    'Wrath'
  ],
  'Zoo York': [
    'Abstract',
    'Abstract Lace-Up',
    'Boddington',
    'Booker',
    'Classic',
    'Classic Canvas',
    'Middletown',
    'Skulls',
    'Tabu',
    'Vintage',
    'Vintage 2007'
  ]
};

const PARCEL_WEIGHTS = [
  '.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6',
  '6.5',
  '7',
  '7.5',
  '8',
  '8.5',
  '9',
  '9.5'
];

const PARCEL_SIZES = [
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15'
];

const PICKER_STYLE = {
  margin: '.5rem',
  padding: '1rem',
  fontSize: '1.5em',
  fontFamily: 'Open Sans, sans-serif',
  appearance: 'none',
  border: 'none',
  background: 'black',
  color: 'white',
  borderBottom: '1px solid white',
  cursor: 'pointer',
  outline: 'none'
};

const IMAGE_STYLE = {
  backgroundColor: 'white',
  width: '10rem',
  height: '10rem',
  borderRadius: '1rem',
  marginLeft: 'auto',
  marginRight: 'auto'
};

const getSessionUser = () => JSON.parse(
  sessionStorage.getItem('user') || '{}'
);

const Publish = () => {
  const isMobile = useWindowDimensions().width < 900;
  const user = getSessionUser();
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [brand, setBrand] = useState(BRANDS[0]);
  const [products, setProducts] = useState(PRODUCTS[BRANDS[0]]);
  const [product, setProduct] = useState(products[0]);
  const [condition, setCondition] = useState(CONDITIONS.GOOD);
  const [foot, setFoot] = useState(FOOT.LEFT);
  const [size, setSize] = useState(SIZES[0]);
  const [galleryPhoto1, setGalleryPhoto1] = useState('');
  const [galleryPhoto2, setGalleryPhoto2] = useState('');
  const [galleryPhoto3, setGalleryPhoto3] = useState('');
  const [weight, setWeight] = useState(PARCEL_WEIGHTS[0]);
  const [length, setLength] = useState(PARCEL_SIZES[5]);
  const [width, setWidth] = useState(PARCEL_SIZES[5]);
  const [height, setHeight] = useState(PARCEL_SIZES[5]);

  useEffect(() => {
    if (!user?.userData?.shippingAddress?.street1) {
      window.location.href = '/address/seller';

      return;
    }

    if (!user?.userData?.fernUsername) {
      window.location.href = '/fern/seller';

      return;
    }

    const {
      street1,
      city,
      state,
      zip
    } = user.userData.shippingAddress;

    setAddress1(street1);
    setAddress2(`${city}, ${state} ${zip}`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedBrand = PRODUCTS[brand];

    if (selectedBrand?.length) {
      setProducts(selectedBrand);
      setProduct(selectedBrand[0]);
    }
  }, [brand]);

  if (!user?.userData) {
    window.location.href = '/signin';

    return <Text>Redirecting...</Text>;
  }

  const onPressPublish = async () => {
    if (!user?.userData || !galleryPhoto1) return;

    const {
      username,
      token
    } = user;

    const street1 = address1 || window.prompt('Please confirm your street address (ex: 123 Any St.).');
    const city = !address2 && window.prompt('Please confirm your city (ex: San Francisco).');
    const state = !address2 && window.prompt('Please confirm your state (ex: CA).');
    const zip = !address2 && window.prompt('Please confirm your zip code (ex: 94117).');

    const response = await fetch(
      `${BASE_URL}/publish`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: username,
          token,

          listing: {
            brand,
            product,
            condition,
            foot,
            size,
            gallery: [
              galleryPhoto1,
              galleryPhoto2,
              galleryPhoto3
            ],
            parcel: {
              weight,
              length,
              width,
              height
            }
          },

          address1: address1 || street1,
          address2: address2 || `${city}, ${state} ${zip}`
        })
      }
    );

    if (response?.ok) {
      alert('Published!');

      window.location.href = '/shop';
    }
  };

  if (!products?.length) {
    return <Loading />;
  }

  return (
    <View style={{
      backgroundColor: 'black',
      marginTop: '1rem',
      padding: '2rem',
      display: 'flex'
    }}>
      <Heading secondary value="List a shoe" />
      <View>
        <Text secondary style={{
          fontSize: '1.5em',
          textAlign: 'center',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          opacity: .2
        }}>
          Item
        </Text>
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>Brand</Text>
        <Picker
          onValueChange={value => setBrand(value)}
          style={PICKER_STYLE}
        >
          {BRANDS.map(label => (
            <Picker.Item key={label} label={label} />
          ))}
        </Picker>
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>Product</Text>
        <Picker
          onValueChange={value => setProduct(value)}
          style={PICKER_STYLE}
        >
          {products.map(label => (
            <Picker.Item key={label} label={label} />
          ))}
        </Picker>
      </View>
      <View style={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row'
      }}>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Foot</Text>
          <Picker
            onValueChange={value => setFoot(value)}
            style={PICKER_STYLE}
          >
            {(Object.values(FOOT)).map(label => (
              <Picker.Item key={label} label={label} />
            ))}
          </Picker>
        </View>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Size</Text>
          <Picker
            onValueChange={value => setSize(value)}
            style={PICKER_STYLE}
          >
            {SIZES.map(label => (
              <Picker.Item key={`size-${label}`} label={label} />
            ))}
          </Picker>
        </View>
      </View>
      <View style={{ marginTop: '1rem' }}>
        <Text secondary>Condition</Text>
        <Picker
          onValueChange={value => setCondition(value)}
          style={PICKER_STYLE}
        >
          {(Object.values(CONDITIONS)).map(label => (
            <Picker.Item key={label} label={label} />
          ))}
        </Picker>
      </View>
      <View>
        <Text secondary style={{
          fontSize: '1.5em',
          textAlign: 'center',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          opacity: .2
        }}>
          Parcel
        </Text>
      </View>
       <View style={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'row'
      }}>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Weight (lbs)</Text>
          <Picker
            onValueChange={value => setWeight(value)}
            style={PICKER_STYLE}
          >
            {PARCEL_WEIGHTS.map(label => (
              <Picker.Item key={`weight-${label}`} label={label} />
            ))}
          </Picker>
        </View>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Length (in)</Text>
          <Picker
            onValueChange={value => setLength(value)}
            style={PICKER_STYLE}
          >
            {PARCEL_SIZES.map((label, index) => (
              <Picker.Item key={`length-${label}-${index}`} label={label} />
            ))}
          </Picker>
        </View>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Width (in)</Text>
          <Picker
            onValueChange={value => setWidth(value)}
            style={PICKER_STYLE}
          >
            {PARCEL_SIZES.map((label, index) => (
              <Picker.Item key={`width-${label}-${index}`} label={label} />
            ))}
          </Picker>
        </View>
        <View style={{
          flex: 1
        }}>
          <Text secondary>Height (in)</Text>
          <Picker
            onValueChange={value => setHeight(value)}
            style={PICKER_STYLE}
          >
            {PARCEL_SIZES.map((label, index) => (
              <Picker.Item key={`height-${label}-${index}`} label={label} />
            ))}
          </Picker>
        </View>
      </View>
      <View>
        <Text secondary style={{
          fontSize: '1.5em',
          textAlign: 'center',
          marginTop: '1.5rem',
          opacity: .2
        }}>
          Photos
        </Text>
      </View>
      <View style={{
        marginTop: '1rem',
        padding: '2rem',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? '1rem' : 0
      }}>
        <View style={{
          flex: 1
        }}>
          {galleryPhoto1
            ? <Image
                source={{ uri: galleryPhoto1 }}
                style={IMAGE_STYLE}
              />
            : <ImagePicker
                style={IMAGE_STYLE}
                onChange={setGalleryPhoto1}
              />
          }
        </View>
        <View style={{
          flex: 1
        }}>
          {galleryPhoto2
            ? <Image
                source={{ uri: galleryPhoto2 }}
                style={IMAGE_STYLE}
              />
            : <ImagePicker
                style={IMAGE_STYLE}
                onChange={setGalleryPhoto2}
              />
          }
        </View>
        <View style={{
          flex: 1
        }}>
          {galleryPhoto3
            ? <Image
                source={{ uri: galleryPhoto3 }}
                style={IMAGE_STYLE}
              />
            : <ImagePicker
                style={IMAGE_STYLE}
                onChange={setGalleryPhoto3}
              />
          }
        </View>
      </View>
      <Button
        center
        secondary
        onPress={onPressPublish}
        value={
          <View style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Text>Publish</Text>
            <FontAwesomeIcon icon={faPlus} />
          </View>
        }
      />
    </View>
  );
};

export default Publish;
