import { View, useWindowDimensions } from 'react-native';

import styles from './styles';

const Container = ({
  style = {},
  children = '',

  ...props
}) => {
  const isMobile = useWindowDimensions().width < 900;

  return (
    <View
      {...props}

      style={{
        ...style,

        ...(isMobile ? styles.mobile : styles.medium)
      }}
    >
      {children}
    </View>
  );
};

export default Container;
