import { StyleSheet } from 'react-native';

const BASE_HEADING_STYLES = {
  fontFamily: 'Bodoni Moda, serif',
  fontWeight: '500',
  fontSize: '4em'
};

const BASE_BANNER_STYLES = {
  width: '100%',
  minWidth: '900px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'no-wrap'
};

const BANNER_STYLES = {
  ...BASE_HEADING_STYLES,
  ...BASE_BANNER_STYLES,

  fontSize: '9em',
  textTransform: 'uppercase',
  textAlign: 'center',
  height: '85vh',
  lineHeight: '85vh',
  backgroundImage: 'url("/img/kickflip.gif")',
  backgroundSize: 'cover',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  backgroundRepeat: 'no-repeat'
};

const LOGO_STYLES = {
  ...BASE_HEADING_STYLES,
  ...BASE_BANNER_STYLES,

  fontSize: '5em',
  textTransform: 'uppercase',
  textAlign: 'center',
  marginTop: '1rem',
  marginBottom: '1rem'
};

const styles = StyleSheet.create({
  primary: {
    ...BASE_HEADING_STYLES,

    color: 'black'
  },

  secondary: {
    ...BASE_HEADING_STYLES,

    color: 'white'
  },

  banner: {
    ...BANNER_STYLES
  },

  logo: {
    ...LOGO_STYLES
  },

  mobileBanner: {
    ...BANNER_STYLES,

    minWidth: 0,
    fontSize: '5em',
    height: '100vh',
    lineHeight: '100vh',
    borderBottomWidth: '5rem',
    borderBottomStyle: 'solid',
    borderBottomColor: 'black'
  },

  mobileLogo: {
    ...LOGO_STYLES,

    minWidth: 0,
    marginTop: '4rem'
  }
});

export default styles;
