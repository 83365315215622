const Privacy = {
  heading: 'Privacy Notice',
  paragraphs: [
    {
      heading: 'Last updated June 9, 2022',
      text: 'This privacy notice for exactchange ("Kickflip", "we", "us", "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services").'
    },
    {
      heading: 'Questions or Concerns?',
      text: 'Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies, please do not use our Services. If you still have questions or concerns, please contact the creator of Kickflip directly at benny@exactchange.network.'
    },
    {
      heading: 'What personal information do we process?',
      text: 'When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Kickflip and the Services, the choices you make, and the products and features you use.'
    },
    {
      heading: 'Do we process any sensitive information?',
      text: 'We do not process sensitive personal information.'
    },
    {
      heading: 'How do we process your information?',
      text: 'We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.'
    },
    {
      heading: 'How do we keep your information safe?',
      text: 'We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to collect, access, steal, or modify your information.'
    },
    {
      heading: 'What are your rights?',
      text: 'Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.'
    },
    {
      heading: 'How do you exercise your rights?',
      text: 'The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.'
    },
    {
      heading: 'What information do we collect?',
      text: 'We collect personal information that you voluntarily provide to us when you register with our Services, express an interest in obtaining information about us or our products and Services, when you participate in activities with our Services, or when you contact us.'
    },
    {
      heading: 'The personal information we collect may include the following:',
      text: 'Names, email addresses, mailing addresses, usernames, passwords, contact preferences, billing addresses, and debit/credit card numbers. We do not process sensitive information.'
    },
    {
      heading: 'What payment data do we collect?',
      text: 'We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (credit card number), and the security code associated with it. All payment data is stored by Square. You may find their privacy notice link here: https://squareup.com/us/en/legal/general/privacy'
    },
    {
      heading: 'We may collect the following information if you provide us with access or permission:',
      text: 'Geolocation information, mobile device access, mobile device data, and push notifications.'
    },
    {
      heading: 'We automatically collect usage data associated with your use of our Services, including:',
      text: 'Log and usage data, device data, and location data.'
    },
    {
      heading: 'We process your information for a variety of reasons, depending on how you interact with our Services, including:',
      text: 'To facilitate account creation and authentication, to facilitate delivery of services to the user, to respond to user inquiries and to offer support, to send administrative information to you, to fulfill and manage your orders, to enable user-to-user communication, and to occasionally send marketing and promotional communications.'
    },
    {
      heading: 'We may share your data with third-party agents in the following service categories:',
      text: 'Cloud computing services, data analytics services, communication & collaboration tools, data storage service providers, order fulfillment service providers, payment processors, user account registration & authentication services, and website hosting service providers.'
    },
    {
      heading: 'Do California residents have specific privacy rights?',
      text: 'Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information. California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit it in writing to us using the contact information provided below.'
    },
    {
      heading: 'Do we make updates to this notice?',
      text: 'We may update this privacy notice occasionally. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible.'
    },
    {
      heading: 'How can you contact us about this notice?',
      text: 'If you have questions or comments about this notice, you may email us at benny@exactchange.network or by mail at 35 Belvedere St. San Francisco, CA 94117.'
    }
  ]
};

export {
  Privacy
};
